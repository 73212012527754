//  配置文件

// 开发环境或者默认配置
let config = {
    request_url: 'http://base.mfdemo.cn',
    request_timeout: 10, // 请求超时时间  单位s,
}

// 非开发环境下配置
// if (process.env.NODE_ENV != 'development') {
//     config.request_url = window.location.protocol + '//' + window.location.host + '/'; // 请求地址
//     config.ossDir = 'SCRM'
// }

// config.imgUpload = config.request_url + '/admin/home/imgUpload';
export default config