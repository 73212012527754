
const modulesFiles = require.context('./modules', true, /\.js$/)
let module = [];
modulesFiles.keys().reduce((modules, modulePath) => {
	const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1')
	const value = modulesFiles(modulePath);
	Object.assign(module,value.default);
}, {})

export default {
	...module,
}