//  配置常量
let consts = {
    on_show: [
        { label: '显示', id: 1 },
        { label: '隐藏', id: 2 }
    ],
    is_admin: [
        { label: '是', id: 1 },
        { label: '不是', id: 0 }
    ],
    is_disable: [
        { label: '正常', id: 1 },
        { label: '禁用', id: 2 }
    ],
    statusLS: [
        { key: '已领取', value: 1 },
        { key: '未领取', value: 2 },
    ],
    statusStaff: [
        { key: '开启', value: 1 },
        { key: '关闭', value: 2 },
    ],
    time: [
        // { key: '按月查看', value: 1 },
        { key: '按日查看', value: 2 },
    ],
}
export default consts