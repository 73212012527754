// 公共方法
import { Message, Loading } from 'element-ui';
import router from '../router'
let timeout;

//弹窗
export function toast(text, type) {
    debounce(function() {
        Message({
            message: text,
            type: type || "error"
        })
    });
}
//校验密码：只能输入6-20个字母、数字、下划线
export function isPasswd(s) {
    var patrn = /^(\w){4,6}$/;
    if (!patrn.exec(s)) return false
    return true
}
//加载动画
export function loading() {
    Loading.service();
}

//跳转登录
export function goLogin(time) {
    debounce(function() {
        toast('请先登录');
        jumpPage('/login', time || '2000');
    });
}

//返回上一页
export function goBack(time) {
    setTimeout(function() {
        router.go(-1);
    }, time || 1000)
}

export function getUrlParam() {
    var url = location.search;
    var theRequest = new Object()
    if (url.indexOf("?") != -1) {
        var str = url.substr(1)
        var strs = str.split("&");
        for (var i = 0; i < strs.length; i++) {
            theRequest[strs[i].split("=")[0]] = (strs[i].split("=")[1])
        }
    }
    return theRequest
}

//页面跳转
export function jumpPage(page, time, param) {
    setTimeout(function() {
        router.push({ path: page, query: param || {} })
    }, time || 1000)
}

//随机数
export function randCode(length = 6, numeric = true) {
    let chars = Math.floor(Math.random() * Math.pow(10, length)).toString();
    if (!numeric) {
        chars = 'ABCDEFGHJKLMNPQRSTUVWXYZ123456789abcdefghjkmnpqrstuvwxyz';
    }
    let hash = '';
    let maxLength = chars.length - 1;

    for (let i = 0; i < length; ++i) {
        let index = Math.floor(Math.random() * (maxLength - 1 + 1)) + 1;
        hash += chars.substr(index, 1);
    }
    return hash;
}

//节流
export function throttle(func, wait = 500, immediate = true) {
    if (immediate) {
        if (!flag) {
            flag = true;
            // 如果是立即执行，则在wait毫秒内开始时执行
            typeof func === 'function' && func();
            timer = setTimeout(() => {
                flag = false;
            }, wait);
        }
    } else {
        if (!flag) {
            flag = true
                // 如果是非立即执行，则在wait毫秒内的结束处执行
            timer = setTimeout(() => {
                flag = false
                typeof func === 'function' && func();
            }, wait);
        }
    }
};


//防抖
export function debounce(func, wait = 500, immediate = false) {
    // 清除定时器
    if (timeout !== null) clearTimeout(timeout);
    // 立即执行，此类情况一般用不到
    if (immediate) {
        var callNow = !timeout;
        timeout = setTimeout(function() {
            timeout = null;
        }, wait);
        if (callNow) typeof func === 'function' && func();
    } else {
        // 设置定时器，当最后一次操作后，timeout不会再被清除，所以在延时wait毫秒后执行func回调方法
        timeout = setTimeout(function() {
            typeof func === 'function' && func();
        }, wait);
    }
}

//获取对象中某些元素值
export function getFieldObj(data, field) {
    let res = {};
    for (let key in data) {
        if (field.split(',').indexOf(key) > -1) {
            res[key] = data[key];
        }
    }
    return res;
}

//同步storage
export function synchroStorage() {
    return new Promise((resolve, reject) => {
        if (!sessionStorage.length) {
            localStorage.setItem('getSessionStorage', Date.now());
        };
        window.addEventListener('storage', function(event) {
            if (event.key == 'getSessionStorage') {
                // 已存在的标签页会收到这个事件
                localStorage.setItem('sessionStorage', JSON.stringify(sessionStorage));
                localStorage.removeItem('sessionStorage');
            } else if (event.key == 'sessionStorage' && !sessionStorage.length) {
                // 新开启的标签页会收到这个事件
                var data = JSON.parse(event.newValue);
                for (let key in data) {
                    sessionStorage.setItem(key, data[key]);
                }
            }
        });
    })
}