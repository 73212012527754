<template>
  <div class="page">
    <!-- <Loading v-if="isShow" /> -->
    <el-container>
      <div class="banner">
        <div class="leftImg">
          <img class="logo" style="cursor: pointer;" @click="goIndex()" src="@/assets/image/header.png" alt />
        </div>
        <div class="tag">
          <el-menu :default-active="$route.path" class="el-menu-demo" mode="horizontal" text-color="#fff"
            active-text-color="#fb791c" router>
            <el-menu-item v-for="(item, index) in tagList" :key="index" :index="item.path">{{ item.name
              }}</el-menu-item>
          </el-menu>
        </div>
        <!-- <div class="search">
          <img src="../../assets/image/lang.png" alt />
          <span>English</span>
          <img src="../../assets/image/search.png" alt />
        </div> -->
        <div class="menu_icon" @click="changeMenu()">
          <img src="@/assets/image/icon1.png" alt />
          <div class="menu_phone" v-show="menuShow">
            <el-menu :default-active="$route.path" class="el-menu-demo" mode="horizontal" text-color="#fff"
              active-text-color="#fb791c" router>
              <el-menu-item v-for="(item, index) in tagList" :key="index" :index="item.path">{{ item.name
                }}</el-menu-item>
            </el-menu>
          </div>
        </div>
      </div>
      <el-main>
        <router-view />
      </el-main>
      <el-footer>
        <div class="footer">
          <div class="foot_icon">
            <img style="cursor: pointer;" @click="goIndex()" src="../../assets/image/header.png" alt />
            <!-- <div class="icon_box">
              <span>
                <svg-icon icon-file-name="Vector" />
              </span>
              <span>
                <svg-icon icon-file-name="in" />
              </span>
              <span>
                <svg-icon icon-file-name="f" />
              </span>
            </div> -->
          </div>
          <div class="tagItem">
            <a style="cursor: pointer;" v-for="(item, index) in tagList" :key="index" @click="goPath(item.path)">
              {{
            item.name
          }}
            </a>
          </div>
          <div class="foot_end">
            <div class="code">
              <span style="margin-right: 70px">©️2023 智能科技有限公司</span>
              <span style="cursor: pointer;" @click="goLink()">{{ Btel }}</span>
            </div>
            <div class="yinsi">
              <span @click="webStatement()" style="cursor: pointer;">网站声明</span>
              <span @click="policyStatement()" style="margin-left: 36px;cursor: pointer;">隐私政策</span>
            </div>
          </div>
        </div>
        <div class="wenti" @click="goWe()">
          <img src="@/assets/image/wenti_icon.png" alt />
        </div>
      </el-footer>
    </el-container>
  </div>
</template>

<script>
// import Loading from '@/components/loading.vue'
import axios from 'axios'
export default {
  data () {
    return {
      Btel: '',
      Blink: '',
      isShow: true,
      menuShow: false,
      // fsvg: f,
      formData: {
        projectId: 21,
      },
      activeIndex: '/home',
      tagList: [],
    }
  },
  // components: {
  //   Loading,
  // },
  created () {
    this.getTree()
    // setTimeout(() => {
    //   this.isShow = false
    // }, 3000)
  },
  methods: {
    goLink () {
      window.open(this.Blink, '_blank')
    },
    getTree () {
      axios.get('https://base.mfdemo.cn/boot/sys/column/tree', { params: { ...this.formData } }).then((res) => {
        // console.log(JSON.parse(res.data.data[0].children[5].attachList[0].contents), 789)
        this.Btel = JSON.parse(res.data.data[0].children[5].attachList[0].contents)[0].value
        this.Blink = JSON.parse(res.data.data[0].children[5].attachList[0].contents)[1].value
        this.tagList = res.data.data.slice(0, 6)
      })
    },


    webStatement () {
      const { href } = this.$router.resolve({
        name: "web",
      });
      window.open(href, '_blank');
    },
    policyStatement () {
      const { href } = this.$router.resolve({
        name: "policy",
      });
      window.open(href, '_blank');
    },


    goIndex () {
      this.$router.push('/')
    },
    goWe () {
      this.$router.push('/we')
    },
    goPath (path) {
      this.$router.push(path)
    },
    changeMenu () {
      this.menuShow = !this.menuShow
    },
  },
}
</script>

<style scoped lang="scss">
:deep(.el-menu-item):hover {
  color: #fa6c10 !important;
}

.el-menu-demo {
  margin-right: 80px;
}

.yinsi {
  display: block;
}

.menu_icon {
  display: none;
  margin-right: 20px;
  position: relative;

  .menu_phone {
    position: absolute;
    top: 40px;
    background: rgba(0, 0, 0, 0.16);
    right: 0;
    padding: 0 15px;

    :deep(.el-menu-item) {
      font-size: 14px;
    }
  }
}

.foot_end {
  width: 95%;
  margin: 66px auto;
  display: flex;
  justify-content: space-between;

  span {
    color: #333;
    font-family: Source Han Sans CN;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}

.tagItem {
  width: 45%;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;

  a:hover {
    color: #fa6c10;
    // font-weight: bold;
    transition: all .5s;
  }

  a {
    color: rgba(0, 0, 0, 0.54);

    font-family: Alibaba PuHuiTi;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}

.el-footer {
  padding: 0;
  position: relative;

  .wenti {
    width: 106px;
    height: 106px;
    background: #fa6c10;
    border-radius: 50%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    cursor: pointer;
    position: absolute;
    top: 109px;
    right: 15px;
  }
}

.footer {
  width: 100%;
  height: 421px;
  background: #eee;
  overflow: hidden;

  .foot_icon {
    width: 345px;
    height: 164px;
    margin: 32px auto;
    position: relative;

    img {
      position: absolute;
      top: 20px;
    }

    .icon_box {
      width: 50%;
      display: flex;
      justify-content: space-between;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);

      span {
        display: block;
        width: 46px;
        height: 46px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        background: #cfcfcf;
        border-radius: 50%;
        font-size: 18px;
        font-weight: bold;
        color: #fff;
      }
    }
  }
}

.el-menu-item {
  color: rgba(255, 255, 255, 0.85);
  font-family: Alibaba PuHuiTi;
  font-size: 20px;
  font-weight: 400;
  line-height: 60px;
}

.el-menu {
  background: none;
  border: none;
}

.el-menu--horizontal>.el-menu-item {
  border: none;
}

.el-menu--horizontal>.el-menu-item.is-active {
  border-bottom: none;
}

.el-menu.el-menu--horizontal {
  border-bottom: none;
}

.el-menu--horizontal>.el-menu-item:not(.is-disabled):focus,
.el-menu--horizontal>.el-menu-item:not(.is-disabled):hover,
.el-menu--horizontal>.el-submenu .el-submenu__title:hover {
  background: none;
}

.banner {
  position: fixed;
  inset: 0 0 auto 0;
  z-index: 999;
  @include flexBetween;
  width: 100%;
  height: 99px;
  padding-left: 57px;
  flex-shrink: 0;
  background: rgba(0, 0, 0, 0.16);
  z-index: 999;
  backdrop-filter: blur(5px);

  .leftImg {
    img {
      height: 99px;
      transform: translateY(14px);
    }
  }

  .right {
    justify-content: space-between;
    flex: 1;
    // padding-left: 290px;
    height: 100%;
    @include flex;

    // padding: 37px 110px 35px auto;
    .tag {
      width: 80%;
      margin-right: 50px !important;
    }
  }
}

.search {
  width: 15%;
  display: flex;
  align-items: center;

  span {
    color: #fff;
    font-family: Alibaba PuHuiTi;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    margin: 0 67px 0 10px;
  }

  img {
    width: 24px;
  }
}

.el-main {
  padding: 0;
  overflow: hidden;
}

@media screen and (max-width: 600px) {
  .search {
    display: none;
  }

  .banner {
    padding-left: 15px;
    height: 60px;

    .logo {
      width: 188px;
      height: 75px !important;
    }

    .tag {
      display: none;
    }

    .menu_icon {
      display: block;
    }
  }

  .footer {
    .foot_icon {
      width: 80%;
      height: 140px;
      position: relative;
      margin-bottom: 15px;

      img {
        width: 187px;
        display: block;
      }

      .icon_box {
        width: 120px;
        position: absolute;
        top: 80px;
        left: 0;
        transform: translateX(15px);

        span {
          width: 30px;
          height: 30px;
        }
      }
    }

    .tagItem {
      width: 80%;

      a {
        font-size: 12px;
      }
    }

    .foot_end {
      width: 90%;
      border-top: 1px solid #d9d9d9;
      margin: 20px auto;
      padding-top: 15px;

      .code {
        padding: 0 5%;
        width: 100%;
        display: flex;
        justify-content: space-between;
      }

      span {
        font-size: 12px;
      }

      .yinsi {
        display: none;
      }
    }
  }

  .wenti {
    width: 50px !important;
    height: 50px !important;
    top: 80px !important;
    right: 25px !important;

    img {
      width: 25px;
    }
  }

  .footer {
    height: 300px;
  }
}
</style>
