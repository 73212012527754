import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/components/layout/index.vue'
Vue.use(VueRouter)

const routes = [{
    path: '/',
    redirect: '/home',
},
{
    path: '/',
    name: 'Layout',
    component: Layout,
    children: [{
        path: '/home',
        name: 'home',
        meta: {
            title: '首页',
        },
        component: () =>
            import('@/views/home/index.vue'),
    },
    {
        path: '/product',
        name: 'product',
        meta: {
            title: '产品中心',
        },
        component: () =>
            import('@/views/product/index.vue'),
    },
    {
        path: '/product_detail',
        name: 'product_detail',
        meta: {
            title: '产品详情',
        },
        component: () =>
            import('@/views/product/detail.vue'),
    },
    {
        path: '/solution',
        name: 'solution',
        meta: {
            title: '解决方案',
        },
        component: () =>
            import('@/views/solution/index.vue'),
    },
    {
        path: '/about',
        name: 'about',
        meta: {
            title: '关于我们',
        },
        component: () =>
            import('@/views/about/index.vue'),
    },
    {
        path: '/news',
        name: 'news',
        meta: {
            title: '新闻资讯',
        },
        component: () =>
            import('@/views/news/index.vue'),
    },
    {
        path: '/news_detail',
        name: 'news_detail',
        meta: {
            title: '新闻资讯',
        },
        component: () =>
            import('@/views/news/detail.vue'),
    },
    {
        path: '/we',
        name: 'we',
        meta: {
            title: '关于我们',
        },

        component: () =>
            import('@/views/we/index.vue'),
    },
    {
        path: '/web',
        name: 'web',
        meta: {
            title: '网站声明',
        },

        component: () =>
            import('@/views/web/index.vue'),
    },
    {
        path: '/policy',
        name: 'policy',
        meta: {
            title: '隐私政策',
        },

        component: () =>
            import('@/views/policy/index.vue'),
    },
    ],
},
]

const router = new VueRouter({
    routes,
    scrollBehavior (to, from, savedPosition) {
        return { x: 0, y: 0 };
    }
})
router.beforeEach((to, from, next) => {
    if (to.meta && to.meta.title) {
        document.title = to.meta.title + '_扬州新达再生资源'; // 设置浏览器标题
    }
    next();
});


export default router