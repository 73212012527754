import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import config from './config/index.js'
import consts from './config/consts.js'
import api from './api/index.js'
import * as commonFun from './utils/function.js'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI)

import '@/icons/index.js'


import 'animate.css/animate.min.css'

//引入echarts
import echarts from "echarts";
Vue.prototype.$echarts = echarts;




Vue.config.productionTip = false

Vue.prototype.$config = config
Vue.prototype.$const = consts
Vue.prototype.$api = api
Vue.prototype.$commonFun = commonFun

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount('#app')